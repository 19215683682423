export const defaultAddressTypeMap: Record<string, number> = {
    Invoice: 1,
    Delivery: 2,
    SWIFT: 4,
    Payment: 5,
    Service: 6,
    Home: 7,
    Other: 8,
    Business: 9,
    RemitTo: 10,
    Statement: 12,
    FixedAsset: 15,
    Onetime: 16,
    Recruit: 17,
    Lading: 101,
    Unlading: 102,
    Reallocation: 150,
    HeadCompany: 151,
    StableOrganization: 152,
    Consignment: 103,
    ProductionSite: 3
};
